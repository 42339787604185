/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { getCurrentRegion } from '@Services/commonService';
import config from '@config';
import WrapLayout from '@ila//components/Layout/WrapLayout';
import HeadComponent from '@ila/Common/HeadComponent';
import RenderSections from '@landing_pages/ila/Common/RenderSections';
import { performRequest } from '@utils/microsites/DatoCMSHelperService';
import { getQuery } from '@utils/microsites/ILAGqlQueries';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* Styles */

/**
 *
 */
const ILAProgramPage = ({ pageData = [] }) => {
  const [currRegion, setCurrRegion] = useState('in');
  const router = useRouter();
  useEffect(() => {
    (async () => {
      /* Logging the value of currRegion and currRegion?.country.toLowerCase() */
      let cRegion;
      try {
        cRegion = await getCurrentRegion();
        // console.log('cRegion', cRegion);
        setCurrRegion(router?.query?.region || cRegion?.country?.toLowerCase());
      } finally {
        if (cRegion?.length === 0) {
          setCurrRegion('in');
        }
      }
    })();
  }, []);
  const seoData = [
    {
      tag: 'title',
      content: pageData.title,
      attributes: null,
    },
    {
      tag: 'meta',
      content: null,
      attributes: {
        property: 'og:title',
        content: pageData.title,
      },
    },
    {
      tag: 'meta',
      content: null,
      attributes: {
        name: 'description',
        content: pageData.summary || pageData.title,
      },
    },
  ];
  return (
    <WrapLayout pageData={pageData}>
      <HeadComponent
        data={pageData?._seoMetaTags || pageData?.seoFields || seoData}
        availableLocals={pageData?.availableLocales}
      >
        {' '}
      </HeadComponent>
      <Box as="section" className="container" p="0">
        {pageData?.body?.map(section => {
          return (
            <RenderSections
              pageData={pageData}
              section={section}
              region={''}
              lang={''}
              currRegion={currRegion}
              key={nanoid(4)}
            />
          );
        })}
      </Box>
    </WrapLayout>
  );
};

export const getStaticProps = async ({
  preview,
  previewData,
  params: { programType },
}) => {
  try {
    const response = await performRequest(getQuery(`${programType}`));
    // const response = await performRequest(ILA_INSIGHT_PAGE.fetchIlaInsightPage);
    const [ilaInsightPage] = response.allIlaLandingPages;
    let pageData = ilaInsightPage || [];
    pageData = JSON.stringify(pageData).replace(
      /https:\/\/www.datocms-assets.com\//g,
      `${config.staticPath}/d/`
    );
    pageData = JSON.parse(pageData);
    return {
      props: {
        pageData: pageData ?? '',
      },
    };
  } catch (e) {
    const errorMsg = 'Failed fetching records from Dato CMS.';
    console.error(`<<ERROR_MSG: ${errorMsg}  || ERROR_CAUSE: ${e}>>`);
    throw Error(errorMsg + e);
  }
};

export const getStaticPaths = async () => {
  return {
    paths: [
      {
        params: {
          programType: 'insight',
        },
      },
      {
        params: {
          programType: 'brand-insight',
        },
      },
      {
        params: {
          programType: 'family-enterprise-excellence',
        },
      },
      {
        params: {
          programType: 'human-is-not-a-resource',
        },
      },
    ],
    fallback: false,
  };
};

export default ILAProgramPage;
